import { combineReducers } from 'redux';
import {
    SHOW_ADD_SESSION_DIALOG,
    HIDE_ADD_SESSION_DIALOG,
    SHOW_DELETE_SESSION_DIALOG,
    HIDE_DELETE_SESSION_DIALOG,

    ADD_SESSION_SUCCESS,
    ADD_SESSION_IN_PROGRESS,
    ADD_SESSION_ERROR,

    DELETE_SESSION_SUCCESS,
    DELETE_SESSION_IN_PROGRESS,
    DELETE_SESSION_ERROR,

    UPDATE_SESSION_SUCCESS,
    UPDATE_SESSION_IN_PROGRESS,
    UPDATE_SESSION_ERROR,

    SET_SESSION_NAME,
    SET_SESSION_DESCRIPTION,
    SET_SESSION_DATE,
    SET_SESSION_IMAGE_URL,
    SET_SESSION_DAY,
} from '../constants/sessions';

const addSession = (state = {
    dialog: false,
    inProgress: false,
    value: {},
    image: '',
    name: '',
    day: '',
    description: '',
    date: null,
}, action) => {
    switch (action.type) {
    case SHOW_ADD_SESSION_DIALOG:
        return {
            ...state,
            dialog: true,
            value: action.value,
            image: action.value && action.value.image_URL ? action.value.image_URL : '',
            name: action.value && action.value.name ? action.value.name : '',
            day: action.value && action.value.day ? action.value.day : '',
            description: action.value && action.value.description ? action.value.description : '',
            date: action.value && action.value.date ? action.value.date : null,
        };
    case SET_SESSION_IMAGE_URL:
        return {
            ...state,
            image: action.value,
        };
    case SET_SESSION_NAME:
        return {
            ...state,
            name: action.value,
        };
    case SET_SESSION_DAY:
        return {
            ...state,
            day: action.value,
        };
    case SET_SESSION_DESCRIPTION:
        return {
            ...state,
            description: action.value,
        };
    case SET_SESSION_DATE:
        return {
            ...state,
            date: action.value,
        };
    case HIDE_ADD_SESSION_DIALOG:
        return {
            ...state,
            dialog: false,
            image: '',
            name: '',
            day: '',
            description: '',
            date: null,
        };
    case ADD_SESSION_IN_PROGRESS:
    case DELETE_SESSION_IN_PROGRESS:
    case UPDATE_SESSION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_SESSION_SUCCESS:
    case UPDATE_SESSION_SUCCESS:
        return {
            ...state,
            inProgress: false,
            dialog: false,
        };
    case ADD_SESSION_ERROR:
    case UPDATE_SESSION_ERROR:
    case DELETE_SESSION_SUCCESS:
    case DELETE_SESSION_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SHOW_DELETE_SESSION_DIALOG:
        return {
            open: true,
            value: action.value,
        };
    case HIDE_DELETE_SESSION_DIALOG:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    addSession,
    deleteDialog,
});
