export const TICKETS_INFO_FETCH_IN_PROGRESS = 'TICKETS_INFO_FETCH_IN_PROGRESS';
export const TICKETS_INFO_FETCH_SUCCESS = 'TICKETS_INFO_FETCH_SUCCESS';
export const TICKETS_INFO_FETCH_ERROR = 'TICKETS_INFO_FETCH_ERROR';

export const SEND_TICKETS_DIALOG_SHOW = 'SEND_TICKETS_DIALOG_SHOW';
export const SEND_TICKETS_DIALOG_HIDE = 'SEND_TICKETS_DIALOG_HIDE';
export const NO_OF_TICKETS_SET = 'NO_OF_TICKETS_SET';
export const TICKETS_DATA_SET = 'TICKETS_DATA_SET';

export const SEND_TICKETS_SUCCESS = 'SEND_TICKETS_SUCCESS';
export const SEND_TICKETS_IN_PROGRESS = 'SEND_TICKETS_IN_PROGRESS';
export const SEND_TICKETS_ERROR = 'SEND_TICKETS_ERROR';

export const SEND_TICKET_EMAIL_SET = 'SEND_TICKET_EMAIL_SET';
export const SEND_TICKET_TYPE_SET = 'SEND_TICKET_TYPE_SET';

export const FETCH_TICKET_TYPE_IN_PROGRESS = 'FETCH_TICKET_TYPE_IN_PROGRESS';
export const FETCH_TICKET_TYPE_SUCCESS = 'FETCH_TICKET_TYPE_SUCCESS';
export const FETCH_TICKET_TYPE_ERROR = 'FETCH_TICKET_TYPE_ERROR';

export const ORDERS_FETCH_IN_PROGRESS = 'ORDERS_FETCH_IN_PROGRESS';
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS';
export const ORDERS_FETCH_ERROR = 'ORDERS_FETCH_ERROR';

export const SUPPORT_FORMS_FETCH_IN_PROGRESS = 'SUPPORT_FORMS_FETCH_IN_PROGRESS';
export const SUPPORT_FORMS_FETCH_SUCCESS = 'SUPPORT_FORMS_FETCH_SUCCESS';
export const SUPPORT_FORMS_FETCH_ERROR = 'SUPPORT_FORMS_FETCH_ERROR';

export const SUPPORT_FORMS_UPDATE_IN_PROGRESS = 'SUPPORT_FORMS_UPDATE_IN_PROGRESS';
export const SUPPORT_FORMS_UPDATE_SUCCESS = 'SUPPORT_FORMS_UPDATE_SUCCESS';
export const SUPPORT_FORMS_UPDATE_ERROR = 'SUPPORT_FORMS_UPDATE_ERROR';

export const SUPPORT_FORM_DELETE_IN_PROGRESS = 'SUPPORT_FORM_DELETE_IN_PROGRESS';
export const SUPPORT_FORM_DELETE_SUCCESS = 'SUPPORT_FORM_DELETE_SUCCESS';
export const SUPPORT_FORM_DELETE_ERROR = 'SUPPORT_FORM_DELETE_ERROR';

export const DELETE_FORM_DIALOG_SHOW = 'DELETE_FORM_DIALOG_SHOW';
export const DELETE_FORM_DIALOG_HIDE = 'DELETE_FORM_DIALOG_HIDE';

export const STATS_FETCH_IN_PROGRESS = 'STATS_FETCH_IN_PROGRESS';
export const STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS';
export const STATS_FETCH_ERROR = 'STATS_FETCH_ERROR';

export const SESSION_INFO_FETCH_IN_PROGRESS = 'SESSION_INFO_FETCH_IN_PROGRESS';
export const SESSION_INFO_FETCH_SUCCESS = 'SESSION_INFO_FETCH_SUCCESS';
export const SESSION_INFO_FETCH_ERROR = 'SESSION_INFO_FETCH_ERROR';

export const SLOT_ORDERS_FETCH_IN_PROGRESS = 'SLOT_ORDERS_FETCH_IN_PROGRESS';
export const SLOT_ORDERS_FETCH_SUCCESS = 'SLOT_ORDERS_FETCH_SUCCESS';
export const SLOT_ORDERS_FETCH_ERROR = 'SLOT_ORDERS_FETCH_ERROR';
