import { GATED_ACCESS_URL, GATED_ACCESSES_FETCH_URL } from '../constants/url';
import Axios from 'axios';
import {
    ADD_GATED_ACCESS_DIALOG_HIDE,
    ADD_GATED_ACCESS_DIALOG_SHOW,
    ADD_GATED_ACCESS_ERROR,
    ADD_GATED_ACCESS_IN_PROGRESS,
    ADD_GATED_ACCESS_SUCCESS,
    DELETE_GATED_ACCESS_DIALOG_HIDE,
    DELETE_GATED_ACCESS_DIALOG_SHOW,
    DELETE_GATED_ACCESS_ERROR,
    DELETE_GATED_ACCESS_IN_PROGRESS,
    DELETE_GATED_ACCESS_SUCCESS,
    GATED_ACCESS_DESCRIPTION_SET,
    GATED_ACCESS_END_TIME_SET,
    GATED_ACCESS_NAME_SET,
    GATED_ACCESS_START_TIME_SET,
    GATED_ACCESSES_FETCH_ERROR,
    GATED_ACCESSES_FETCH_IN_PROGRESS,
    GATED_ACCESSES_FETCH_SUCCESS,
    UPDATE_GATED_ACCESS_ERROR,
    UPDATE_GATED_ACCESS_IN_PROGRESS,
    UPDATE_GATED_ACCESS_SUCCESS,
} from '../constants/gatedAccess';

const fetchGatedAccessInProgress = () => {
    return {
        type: GATED_ACCESSES_FETCH_IN_PROGRESS,
    };
};

const fetchGatedAccessSuccess = (items) => {
    return {
        type: GATED_ACCESSES_FETCH_SUCCESS,
        items: items,
    };
};

const fetchGatedAccessError = (message) => {
    return {
        type: GATED_ACCESSES_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchGatedAccess = (id) => (dispatch) => {
    dispatch(fetchGatedAccessInProgress());

    const url = GATED_ACCESSES_FETCH_URL(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(fetchGatedAccessSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchGatedAccessError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const showAddGatedAccessDialog = (value) => {
    return {
        type: ADD_GATED_ACCESS_DIALOG_SHOW,
        value,
    };
};

export const hideAddGatedAccessDialog = () => {
    return {
        type: ADD_GATED_ACCESS_DIALOG_HIDE,
    };
};

export const setGatedAccessName = (value) => {
    return {
        type: GATED_ACCESS_NAME_SET,
        value,
    };
};

export const setGatedAccessDescription = (value) => {
    return {
        type: GATED_ACCESS_DESCRIPTION_SET,
        value,
    };
};

export const setGatedAccessStartTime = (value) => {
    return {
        type: GATED_ACCESS_START_TIME_SET,
        value,
    };
};

export const setGatedAccessEndTime = (value) => {
    return {
        type: GATED_ACCESS_END_TIME_SET,
        value,
    };
};

const addGatedAccessInProgress = () => {
    return {
        type: ADD_GATED_ACCESS_IN_PROGRESS,
    };
};

const addGatedAccessSuccess = (value) => {
    return {
        type: ADD_GATED_ACCESS_SUCCESS,
        value,
        message: 'Gated access added successfully',
    };
};

const addGatedAccessError = (message) => {
    return {
        type: ADD_GATED_ACCESS_ERROR,
        message,
        variant: 'error',
    };
};

export const addGatedAccess = (id, data, cb) => (dispatch) => {
    dispatch(addGatedAccessInProgress());

    const url = GATED_ACCESSES_FETCH_URL(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(addGatedAccessSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(addGatedAccessError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const updateGatedAccessInProgress = () => {
    return {
        type: UPDATE_GATED_ACCESS_IN_PROGRESS,
    };
};

const updateGatedAccessSuccess = (value) => {
    return {
        type: UPDATE_GATED_ACCESS_SUCCESS,
        value,
        message: 'Gated access updated successfully',
    };
};

const updateGatedAccessError = (message) => {
    return {
        type: UPDATE_GATED_ACCESS_ERROR,
        message,
        variant: 'error',
    };
};

export const updateGatedAccess = (eventID, id, data, cb) => (dispatch) => {
    dispatch(updateGatedAccessInProgress());

    const url = GATED_ACCESS_URL(eventID, id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(updateGatedAccessSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(updateGatedAccessError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteGatedAccessInProgress = () => {
    return {
        type: DELETE_GATED_ACCESS_IN_PROGRESS,
    };
};

const deleteGatedAccessSuccess = (value) => {
    return {
        type: DELETE_GATED_ACCESS_SUCCESS,
        value,
        message: 'Gated access deleted successfully',
    };
};

const deleteGatedAccessError = (message) => {
    return {
        type: DELETE_GATED_ACCESS_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteGatedAccess = (eventID, id, cb) => (dispatch) => {
    dispatch(deleteGatedAccessInProgress());

    const url = GATED_ACCESS_URL(eventID, id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(deleteGatedAccessSuccess(res.data && res.data.success));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(deleteGatedAccessError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const showDeleteGatedAccessDialog = (value) => {
    return {
        type: DELETE_GATED_ACCESS_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteGatedAccessDialog = () => {
    return {
        type: DELETE_GATED_ACCESS_DIALOG_HIDE,
    };
};
