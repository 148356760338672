import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import { fetchSessionInfo } from '../../actions/home';
import { Button } from '@material-ui/core';
import Icon from '../../components/Icon';
// import { Button } from '@material-ui/core';
import { showAddSessionDialog, showDeleteSessionDialog } from '../../actions/sessions';

const SessionsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        customToolbar: () => {
            return (
                <span>
                    <Button
                        className="add_icon" href=""
                        onClick={() => props.showDialog({})}
                    >
                        <Icon className="add" icon="add"/>
                    </Button>
                </span>
            );
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSessionInfo();
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSessionInfo();
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSessionInfo();
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSessionInfo();
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        // count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const handleUpdate = (value) => {
        props.showDialog(value);
    };

    const columns = [{
        name: 'name',
        label: 'Session Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'description',
        label: 'Session Description',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">{value}
                    </div>
                );
            },
        },
    }, {
        name: 'date',
        label: 'Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'day',
        label: 'Day',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    },
    //     {
    //     name: 'claim.status',
    //     label: 'status',
    //     options: {
    //         customBodyRender: function (value) {
    //             return (
    //                 <div className="">
    //                     {value.status && value.status === 'CLAIMABLE' ? 'Unclaimed' : 'Claimed'}
    //                 </div>
    //             );
    //         },
    //     },
    // },
    {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        <Button
                            className="delete_button"
                            onClick={() => handleUpdate(value)}
                        >
                            Update
                        </Button>
                        <Button
                            className="delete_button"
                            onClick={() => props.showDeleteSessionDialog(value)}
                        >
                            Delete
                            {props.addSessionInProgress ? <CircularProgress/> : null}
                        </Button>
                    </div>
                );
            },
        },
    },
    ];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.name, item.description, item.date, item.day, item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

SessionsTable.propTypes = {
    addSessionInProgress: PropTypes.bool.isRequired,
    fetchSessionInfo: PropTypes.func.isRequired,
    fetchTicketsInfo: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showDeleteSessionDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    showSendTicketsDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.sessions.list,
        inProgress: state.home.tickets.inProgress,
        skip: state.home.tickets.skip,
        limit: state.home.tickets.limit,
        sortBy: state.home.tickets.sortBy,
        order: state.home.tickets.order,
        total: state.home.tickets.count,
        searchKey: state.home.tickets.searchKey,
    };
};

const actionsToProps = {
    fetchSessionInfo,
    showDeleteSessionDialog,
    showDialog: showAddSessionDialog,
};

export default withRouter(connect(stateToProps, actionsToProps)(SessionsTable));
