const variables = {
    en: {
        refund_status: 'Refund Status',
        full_name: 'Full Name',
        email_address: 'Email Address',
        company_project: 'Project',
        add_tickets: 'Add Tickets',
    },
};

export default variables;
