import React, { Component } from 'react';
import './index.css';
import '../Home/index.css';
import BreadCrumb from './BreadCrumb';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGatedAccess } from '../../actions/gatedAccess';
import GatedAccessTable from './GatedAccessTable';
import AddGatedAccessDialog from './AddGatedAccessDialog';
import DeleteDialog from './DeleteDialog';

class GatedAccess extends Component {
    componentDidMount () {
        const eventID = this.props.match && this.props.match.params && this.props.match.params.eventID;

        if (!this.props.gatedAccessesInProgress && eventID) {
            this.props.fetchGatedAccess(eventID);
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            const eventID = this.props.match && this.props.match.params && this.props.match.params.eventID;

            this.props.fetchGatedAccess(eventID);
        }
    }

    render () {
        return (
            <div className="home gated_access">
                <div className="table_div">
                    <h2> CCC Dashboard </h2>
                    <BreadCrumb/>
                    <div className="table_section">
                        <div className="event_table">
                            <GatedAccessTable/>
                        </div>
                    </div>
                </div>
                <AddGatedAccessDialog/>
                <DeleteDialog/>
            </div>
        );
    }
}

GatedAccess.propTypes = {
    fetchGatedAccess: PropTypes.func.isRequired,
    gatedAccesses: PropTypes.array.isRequired,
    gatedAccessesInProgress: PropTypes.bool.isRequired,
    auth: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            eventID: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        gatedAccesses: state.gatedAccess.gatedAccesses.list,
        gatedAccessesInProgress: state.gatedAccess.gatedAccesses.inProgress,
    };
};

const actionToProps = {
    fetchGatedAccess,
};

export default withRouter(connect(stateToProps, actionToProps)(GatedAccess));
