import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog } from '@material-ui/core';
import React from 'react';
import { deleteSupportForm, fetchSupportForms, hideDeleteFormDialog } from '../../actions/home';
import { withRouter } from 'react-router-dom';
import CircularProgress from '../../components/CircularProgress';

const ConfirmDialog = (props) => {
    const handleDelete = () => {
        const data = {
            id: props.value && props.value._id,
        };
        props.deleteSupportForm(props.value && props.value._id, data, (cb) => {
            if (cb) {
                props.hideDeleteFormDialog();
                props.fetchSupportForms(props.skip, props.limit, props.sortBy, props.order);
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog confirm_dialog"
            open={props.open}
            onClose={props.hideDeleteFormDialog}>
            <div className="delete_form">
                <p> Are you sure you want to delete this form?
                </p>
                <div className="delete_actions">
                    <Button
                        className="cancel_button"
                        onClick={props.hideDeleteFormDialog}>
                        Cancel
                    </Button>
                    {props.deleteInProgress
                        ? <Button>
                            <CircularProgress/>
                            Confirm
                        </Button>
                        : <Button
                            onClick={handleDelete}>
                            Confirm
                        </Button>}
                </div>
            </div>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    deleteId: PropTypes.string.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    deleteSupportForm: PropTypes.func.isRequired,
    fetchSupportForms: PropTypes.func.isRequired,
    hideDeleteFormDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    order: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.deleteFormDialog.open,
        value: state.home.deleteFormDialog.value,
        deleteInProgress: state.home.deleteSupportForm.inProgress,
        deleteId: state.home.deleteSupportForm.value,

        skip: state.home.supportForms.skip,
        limit: state.home.supportForms.limit,
        sortBy: state.home.supportForms.sortBy,
        order: state.home.supportForms.order,
        total: state.home.supportForms.count,
    };
};

const actionToProps = {
    hideDeleteFormDialog,
    deleteSupportForm,
    fetchSupportForms,
};

export default withRouter(connect(stateToProps, actionToProps)(ConfirmDialog));
