import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import { fetchTicketType } from '../../actions/home';

const TicketTypeTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketType(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: 'name',
        label: 'Ticket Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'price.fiat',
        label: 'Ticket Price',
        options: {
            customBodyRender: function (value) {
                const fiat = value.fiat && value.fiat.amount;
                const currency = value.fiat && value.fiat.currency;
                return (
                    <div className="user_address">
                        {currency === 'usd'
                            ? (fiat / 100)
                            : fiat}
                        {' '}{currency}
                    </div>
                );
            },
        },
    }, {
        name: 'available_tickets',
        label: 'Available Tickets',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'total_tickets',
        label: 'Total Tickets',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'max_ticket_per_user',
        label: 'Max Ticket Per User',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item) =>
            [item.name, item.price, item.available_tickets, item.total_tickets, item.max_ticket_per_user],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

TicketTypeTable.propTypes = {
    fetchTicketType: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.ticketType.list,
        inProgress: state.home.ticketType.inProgress,
        skip: state.home.ticketType.skip,
        limit: state.home.ticketType.limit,
        sortBy: state.home.ticketType.sortBy,
        order: state.home.ticketType.order,
        total: state.home.ticketType.count,
        searchKey: state.home.ticketType.searchKey,
    };
};

const actionsToProps = {
    fetchTicketType,
};

export default withRouter(connect(stateToProps, actionsToProps)(TicketTypeTable));
