import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSendTicketEmail } from '../../../actions/home';
import { TextField } from '@mui/material';
import variables from '../../../utils/variables';

const EmailAddressTextField = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value, true);
    };

    return (
        <TextField
            className="text_field"
            error={!props.valid}
            errorText={!props.valid ? variables[props.lang]['email_address_error'] : ''}
            id="email_address"
            name="email_address"
            placeholder={variables[props.lang]['email_address']}
            type="email"
            value={props.value}
            onChange={handleChange}/>
    );
};

EmailAddressTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        valid: state.home.emailSendTicket.valid,
        value: state.home.emailSendTicket.value,
    };
};

const actionsToProps = {
    onChange: setSendTicketEmail,
};

export default connect(stateToProps, actionsToProps)(EmailAddressTextField);
