import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import { fetchSlotOrders } from '../../actions/home';
import { config } from '../../config';
import NetworkImages from '../../components/NetworkImages';

const SlotOrdersTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSlotOrders(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSlotOrders(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSlotOrders(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchSlotOrders(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: '_id',
        label: 'Slot order ID',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'invoice.status',
        label: 'Invoice',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value && value.status ? value.status : ''}
                    </div>
                );
            },
        },
    }, {
        name: 'slots',
        label: 'Slots',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">{value && value.length} </div>
                );
            },
        },
    }, {
        name: 'user.email_address',
        label: 'Buyers Email',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>{value.email_address}</div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created At',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'payment.mode',
        label: 'Payment Mode',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>{value.mode}</div>
                );
            },
        },
    }, {
        name: 'payable_amount.amount',
        label: 'Amount',
        options: {
            customBodyRender: function (value) {
                const mode = value.payment && value.payment.mode;
                let amount = value.payment && value.payment.transfer &&
                    value.payment.transfer.amount && value.payment.transfer.amount.amount;
                let denom = value.payment && value.payment.transfer &&
                    value.payment.transfer.amount && value.payment.transfer.amount.denom;

                if (mode === 'CRYPTO') {
                    amount = amount / (10 ** config.COIN_DECIMALS);
                    return (
                        <p className="keplr_amount">
                            <NetworkImages alt={denom} name={denom}/>
                            <span>{amount}</span>
                        </p>
                    );
                } else if (mode === 'FIAT') {
                    amount = value.payment && value.payment.intent && value.payment.intent.amount;
                    denom = value.payment && value.payment.intent && value.payment.intent.currency;
                    return (
                        <div className="keplr_amount">
                            {denom === 'usd' ? '$' : denom && denom.toUpperCase()}
                            &nbsp;
                            {amount && (amount / 100)}
                        </div>
                    );
                }

                return null;
            },
        },
    }, {
        name: 'status',
        label: 'Order Status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item._id,
                item.invoice,
                item.slots,
                item.user,
                item.created_at,
                item.payment,
                item,
                item.status],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

SlotOrdersTable.propTypes = {
    fetchSlotOrders: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.slotOrders.list,
        inProgress: state.home.slotOrders.inProgress,
        skip: state.home.slotOrders.skip,
        limit: state.home.slotOrders.limit,
        sortBy: state.home.slotOrders.sortBy,
        order: state.home.slotOrders.order,
        total: state.home.slotOrders.count,
        searchKey: state.home.slotOrders.searchKey,
    };
};

const actionsToProps = {
    fetchSlotOrders,
};

export default withRouter(connect(stateToProps, actionsToProps)(SlotOrdersTable));
