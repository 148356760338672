export const config = {
    COIN_DECIMALS: 6,
};

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const EVENT_ID = '632954eb02c34818767c5434';
