import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import { Button } from '@material-ui/core';
// import Icon from '../../components/Icon';
import { showAddEventDialog, showDeleteEventDialog } from '../../actions/events';
import { EVENT_ID } from '../../config';

const EventsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onCellClick: (colData, cellMeta) => {
            const data = props.list && props.list[cellMeta && cellMeta.rowIndex];
            if (cellMeta.colIndex !== 7) {
                props.history.push(`/event/${data && data._id}`);
            }
        },
        // customToolbar: () => {
        //     return (
        //         <span>
        //             <Button className="add_icon" href="" onClick={() => props.showDialog({})}>
        //                 <Icon className="add" icon="add"/>
        //             </Button>
        //         </span>
        //     );
        // },
        responsive: 'standard',
        serverSide: false,
        pagination: true,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: '_id',
        label: 'Event ID',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div>{value}</div>
                );
            },
        },
    }, {
        name: 'image_URL',
        label: 'Image',
        options: {
            customBodyRender: function (value) {
                return (
                    <img alt="cosmovers-event" src={value}/>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Event Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'venue',
        label: 'Venue',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'description',
        label: 'Description',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'start_time',
        label: 'Start Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'end_time',
        label: 'End Time',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && moment(value).format('MMM Do, h:mm A')}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="status_button">
                        <Button
                            className="delete_button"
                            onClick={() => props.history.push(`/event/${value && value._id}`)}>
                            View
                        </Button>
                        <Button
                            className="delete_button"
                            onClick={() => props.showDialog(value)}>
                            Update
                        </Button>
                        <Button
                            className="delete_button"
                            onClick={() => props.showDeleteEventDialog(value)}>
                            Delete
                            {props.addEventInProgress ? <CircularProgress/> : null}
                        </Button>
                    </div>
                );
            },
        },
    }];

    let list = props.list;

    if (EVENT_ID) {
        list = list && list.length && list.filter((val) => val._id === EVENT_ID);
    }

    const tableData = list && list.length > 0
        ? list.map((item, index) =>
            [item._id,
                item.image_URL,
                item.name,
                item.venue,
                item.description,
                item.start_time,
                item.end_time,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

EventsTable.propTypes = {
    addEventInProgress: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    showDeleteEventDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.event.events.list,
        inProgress: state.event.events.inProgress,
        addEventInProgress: state.event.addEvent.inProgress,
    };
};

const actionsToProps = {
    showDeleteEventDialog,
    showDialog: showAddEventDialog,
};

export default withRouter(connect(stateToProps, actionsToProps)(EventsTable));
