import * as React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
// import { deleteEvent, fetchEvents, hideDeleteEventDialog } from '../../../actions/events';
import { deleteSession, hideDeleteSessionDialog } from '../../../actions/sessions';
import { fetchSessionInfo } from '../../../actions/home';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../DeleteEventDialog/index.css';
import CircularProgress from '../../../components/CircularProgress';

const DeleteSessionDialog = (props) => {
    const handleDelete = () => {
        props.deleteSession(props.value && props.value._id, (result) => {
            props.fetchSessionInfo();
            props.hideDialog();
        });
    };

    const disable = props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog delete_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Delete Session confirmation
            </DialogTitle>
            {props.inProgress ? <CircularProgress className="full_screen"/> : null}
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                <Button autoFocus className="add_button" disabled={disable} onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteSessionDialog.propTypes = {
    deleteSession: PropTypes.func.isRequired,
    fetchSessionInfo: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.sessions.deleteDialog.open,
        value: state.sessions.deleteDialog.value,
        inProgress: state.sessions.addSession.inProgress,
    };
};

const actionToProps = {
    deleteSession,
    fetchSessionInfo,
    hideDialog: hideDeleteSessionDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(DeleteSessionDialog));
