import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetchOrders,
    fetchSessionInfo,
    fetchSlotOrders,
    fetchStats,
    fetchSupportForms,
    fetchTicketType,
    fetchTicketsInfo,
} from '../../actions/home';
import { fetchEvents } from '../../actions/events';
import TicketsInfoTable from './TicketsInfoTable';
import { withRouter } from 'react-router-dom';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OrdersTable from './OrdersTable';
import SupportFormsTable from './SupportFormsTable';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import EventsTable from './EventsTable';
// import AddEventDialog from './AddEventDialog';
import DeleteDialog from './DeleteEventDialog';
import SendTicketsDialog from './SendTicketsDialog';

import SessionsTable from './SessionsTable';
import AddSessionDialog from './AddSessionDialog';
import DeleteSessionDialog from './DeleteSessionDialog';
import SlotOrdersTable from './SlotOrdersTable';
import TicketTypeTable from './TicketTypeTable';

class Home extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            value: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (event, value) {
        this.setState({ value });
        if (value === 0) {
            if (this.props.tickets && !this.props.tickets.length && !this.props.inProgress) {
                this.props.fetchTicketsInfo(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        } else if (value === 1) {
            if (this.props.orders && !this.props.orders.length && !this.props.ordersInProgress) {
                this.props.fetchOrders(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        } else if (value === 2) {
            if (this.props.supportFormsList && !this.props.supportFormsList.length && !this.props.supportFormsInProgress) {
                this.props.fetchSupportForms(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        } else if (value === 3) {
            if (this.props.events && !this.props.events.length && !this.props.eventsInProgress) {
                this.props.fetchEvents(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        } else if (value === 4) {
            if (this.props.session && !this.props.session.length && !this.props.session.inProgress) {
                this.props.fetchSessionInfo();
            }
        } else if (value === 5) {
            if (this.props.slotOrders && !this.props.slotOrders.length && !this.props.slotOrdersInProgress) {
                this.props.fetchSlotOrders(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        } else if (value === 6) {
            if (this.props.ticketTypes && !this.props.ticketTypes.length && !this.props.ticketTypesInProgress) {
                this.props.fetchTicketType(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            }
        }
    }

    componentDidMount () {
        const token = localStorage.getItem('authorizationToken_CCCDashboard24');
        if (token) {
            this.props.fetchTicketsInfo(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            this.props.fetchStats();
        } else {
            this.props.history.push('/login');
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetchTicketsInfo(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
            this.props.fetchStats();
        }
    }

    render () {
        return (
            <div className="home">
                <div className="table_div">
                    <h2> Cosmoverse 2024 Dashboard </h2>
                    <div className="header">
                        <Tabs
                            className="tabs_section"
                            value={this.state.value}
                            onChange={this.handleChange}>
                            <Tab label={<div>Tickets {' '}
                                {this.props.stats && this.props.stats.tickets
                                    ? <span>({this.props.stats.tickets})</span> : ''}
                            </div>}/>
                            <Tab label={<div>Orders {' '}
                                {this.props.stats && this.props.stats.orders
                                    ? <span>({this.props.stats.orders})</span> : ''}
                            </div>}/>
                            <Tab label={<div>Support Forms {' '}
                                {this.props.stats && this.props.stats.support_forms
                                    ? <span>({this.props.stats.support_forms})</span> : ''}
                            </div>}/>
                            <Tab label={<div>Events {' '}
                            </div>}/>
                            <Tab label={<div>Sessions {' '}
                            </div>} />
                            <Tab label={<div>Slot Orders {' '}
                                {/* {this.props.stats && this.props.stats.orders */}
                                {/*     ? <span>({this.props.stats.orders})</span> : ''} */}
                            </div>}/>
                            <Tab label={<div>Ticket Types {' '}
                            </div>} />
                        </Tabs>
                    </div>
                    <div className="table_section">
                        {this.state.value === 0 && <TicketsInfoTable/>}
                        {this.state.value === 1 && <OrdersTable/>}
                        {this.state.value === 2 && <SupportFormsTable/>}
                        {this.state.value === 3 && <div className="event_table">
                            <EventsTable/>
                        </div>}
                        {this.state.value === 4 &&
                            <div className="event_table"><SessionsTable /></div>}
                        {this.state.value === 5 &&
                            <div><SlotOrdersTable /></div>}
                        {this.state.value === 6 &&
                            <div><TicketTypeTable /></div>}
                    </div>
                </div>
                {this.props.open && <ConfirmDeleteDialog/>}
                {/* <AddEventDialog/> */}
                <DeleteDialog/>
                <SendTicketsDialog />
                <AddSessionDialog />
                <DeleteSessionDialog/>
            </div>
        );
    }
}

Home.propTypes = {
    events: PropTypes.array.isRequired,
    eventsInProgress: PropTypes.bool.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    fetchSessionInfo: PropTypes.func.isRequired,
    fetchSlotOrders: PropTypes.func.isRequired,
    fetchStats: PropTypes.func.isRequired,
    fetchSupportForms: PropTypes.func.isRequired,
    fetchTicketType: PropTypes.func.isRequired,
    fetchTicketsInfo: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired,
    ordersInProgress: PropTypes.bool.isRequired,
    session: PropTypes.array.isRequired,
    slotOrders: PropTypes.array.isRequired,
    slotOrdersInProgress: PropTypes.bool.isRequired,
    stats: PropTypes.object.isRequired,
    supportFormsInProgress: PropTypes.bool.isRequired,
    supportFormsList: PropTypes.array.isRequired,
    ticketTypes: PropTypes.array.isRequired,
    ticketTypesInProgress: PropTypes.bool.isRequired,
    tickets: PropTypes.array.isRequired,
    auth: PropTypes.string,
    inProgress: PropTypes.bool,
    snackbar: PropTypes.string,
    supportFormsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        events: state.event.events.list,
        session: state.home.sessions.list,
        eventsInProgress: state.event.events.inProgress,
        lang: state.language,
        tickets: state.home.tickets.list,
        inProgress: state.home.tickets.inProgress,
        snackbar: state.snackbar.message,
        stats: state.home.stats.value,
        orders: state.home.orders.list,
        ordersInProgress: state.home.orders.inProgress,
        slotOrders: state.home.slotOrders.list,
        slotOrdersInProgress: state.home.slotOrders.inProgress,
        supportFormsTotal: state.home.supportForms.count,
        supportFormsList: state.home.supportForms.list,
        supportFormsInProgress: state.home.supportForms.inProgress,
        open: state.home.deleteFormDialog.open,
        ticketTypes: state.home.ticketType.list,
        ticketTypesInProgress: state.home.ticketType.inProgress,
    };
};

const actionToProps = {
    fetchEvents,
    fetchTicketsInfo,
    fetchOrders,
    fetchSlotOrders,
    fetchStats,
    fetchSupportForms,
    fetchSessionInfo,
    fetchTicketType,
};

export default withRouter(connect(stateToProps, actionToProps)(Home));
