import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSendTicketType } from '../../../actions/home';
import { MenuItem, Select } from '@mui/material';

const TypeSelectField = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <div>
            <p className="type_label">Type</p>
            <Select
                className="text_field select_field"
                id="demo-simple-select-helper"
                // label="Type"
                value={props.value}
                onChange={handleChange}>
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {props.options && props.options.length > 0 &&
                props.options.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            value={item._id} onChange={() => handleChange(item._id)}>
                            {item.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

TypeSelectField.propTypes = {
    lang: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.typeSendTicket.value,
        options: state.home.ticketType.list,
    };
};

const actionsToProps = {
    onChange: setSendTicketType,
};

export default connect(stateToProps, actionsToProps)(TypeSelectField);
