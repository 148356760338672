import {
    SHOW_ADD_SESSION_DIALOG,
    HIDE_ADD_SESSION_DIALOG,
    SHOW_DELETE_SESSION_DIALOG,
    HIDE_DELETE_SESSION_DIALOG,

    ADD_SESSION_SUCCESS,
    ADD_SESSION_IN_PROGRESS,
    ADD_SESSION_ERROR,

    DELETE_SESSION_SUCCESS,
    DELETE_SESSION_IN_PROGRESS,
    DELETE_SESSION_ERROR,

    UPDATE_SESSION_SUCCESS,
    UPDATE_SESSION_IN_PROGRESS,
    UPDATE_SESSION_ERROR,

    SET_SESSION_NAME,
    SET_SESSION_DESCRIPTION,
    SET_SESSION_DATE,
    SET_SESSION_IMAGE_URL,
    SET_SESSION_DAY,
} from '../constants/sessions';
import { SESSION_INFO_URL, urlUpdateSession } from '../constants/url';
import Axios from 'axios';

export const showAddSessionDialog = (value) => {
    return {
        type: SHOW_ADD_SESSION_DIALOG,
        value,
    };
};

export const hideAddSessionDialog = () => {
    return {
        type: HIDE_ADD_SESSION_DIALOG,
    };
};

export const showDeleteSessionDialog = (value) => {
    return {
        type: SHOW_DELETE_SESSION_DIALOG,
        value,
    };
};

export const hideDeleteSessionDialog = () => {
    return {
        type: HIDE_DELETE_SESSION_DIALOG,
    };
};

const addSessionSuccess = (value) => {
    return {
        type: ADD_SESSION_SUCCESS,
        value,
        message: 'Session added Successfully',
    };
};

const addSessionInProgress = () => {
    return {
        type: ADD_SESSION_IN_PROGRESS,
    };
};

const addSessionError = (message) => {
    return {
        type: ADD_SESSION_ERROR,
        message,
        variant: 'error',
    };
};

export const addSession = (data, cb) => (dispatch) => {
    dispatch(addSessionInProgress());

    const url = SESSION_INFO_URL();
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(addSessionSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(addSessionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteSessionInProgress = () => {
    return {
        type: DELETE_SESSION_IN_PROGRESS,
    };
};

const deleteSessionSuccess = (value) => {
    return {
        type: DELETE_SESSION_SUCCESS,
        value,
        message: 'Session Deleted Successfully',
    };
};

const deleteSessionError = (message) => {
    return {
        type: DELETE_SESSION_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteSession = (id, cb) => (dispatch) => {
    dispatch(deleteSessionInProgress());

    const url = urlUpdateSession(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(deleteSessionSuccess(res.data && res.data.success));
            cb(res.data, res.data && res.data.success);
        })
        .catch((error) => {
            dispatch(deleteSessionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const updateSessionInProgress = () => {
    return {
        type: UPDATE_SESSION_IN_PROGRESS,
    };
};

const updateSessionSuccess = (value) => {
    return {
        type: UPDATE_SESSION_SUCCESS,
        value,
        message: 'Session updated successfully',
    };
};

const updateSessionError = (message) => {
    return {
        type: UPDATE_SESSION_ERROR,
        message,
        variant: 'error',
    };
};

export const updateSession = (id, data, cb) => (dispatch) => {
    dispatch(updateSessionInProgress());

    const url = urlUpdateSession(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(updateSessionSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(updateSessionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const setSessionName = (value) => {
    return {
        type: SET_SESSION_NAME,
        value,
    };
};

export const setSessionDescription = (value) => {
    return {
        type: SET_SESSION_DESCRIPTION,
        value,
    };
};

export const setSessionDate = (value) => {
    return {
        type: SET_SESSION_DATE,
        value,
    };
};

export const setSessionImageUrl = (value) => {
    return {
        type: SET_SESSION_IMAGE_URL,
        value,
    };
};

export const setSessionDay = (value) => {
    return {
        type: SET_SESSION_DAY,
        value,
    };
};
