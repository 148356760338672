export const SHOW_ADD_SESSION_DIALOG = 'SHOW_ADD_SESSION_DIALOG';
export const HIDE_ADD_SESSION_DIALOG = 'HIDE_ADD_SESSION_DIALOG';
export const SHOW_DELETE_SESSION_DIALOG = 'SHOW_DELETE_SESSION_DIALOG';
export const HIDE_DELETE_SESSION_DIALOG = 'SHOW_DELETE_SESSION_DIALOG';
export const ADD_SESSION_IN_PROGRESS = 'ADD_SESSION_IN_PROGRESS';
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS';
export const ADD_SESSION_ERROR = 'ADD_SESSION_ERROR';
export const UPDATE_SESSION_IN_PROGRESS = 'UPDATE_SESSION_IN_PROGRESS';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_ERROR = 'UPDATE_SESSION_ERROR';
export const DELETE_SESSION_IN_PROGRESS = 'DELETE_SESSION_IN_PROGRESS';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'DELETE_SESSION_ERROR';
export const SET_SESSION_NAME = 'SET_SESSION_NAME';
export const SET_SESSION_DESCRIPTION = 'SET_SESSION_DESCRIPTION';
export const SET_SESSION_DATE = 'SET_SESSION_DATE';
export const SET_SESSION_IMAGE_URL = 'SET_SESSION_IMAGE_URL';
export const SET_SESSION_DAY = 'SET_SESSION_DAY';
