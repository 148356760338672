import {
    ADD_EVENT_DIALOG_HIDE,
    ADD_EVENT_DIALOG_SHOW,
    ADD_EVENT_ERROR,
    ADD_EVENT_IN_PROGRESS,
    ADD_EVENT_SUCCESS,
    DELETE_EVENT_DIALOG_HIDE,
    DELETE_EVENT_DIALOG_SHOW,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_IN_PROGRESS,
    DELETE_EVENT_SUCCESS,
    EVENT_DESCRIPTION_SET,
    EVENT_END_TIME_SET,
    EVENT_IMAGE_URL_SET,
    EVENT_NAME_SET,
    EVENT_START_TIME_SET,
    EVENT_VENUE_SET,
    EVENTS_FETCH_ERROR,
    EVENTS_FETCH_IN_PROGRESS,
    EVENTS_FETCH_SUCCESS,
    UPDATE_EVENT_ERROR,
    UPDATE_EVENT_IN_PROGRESS,
    UPDATE_EVENT_SUCCESS,
} from '../constants/events';
import { EVENT_URL, EVENTS_FETCH_URL } from '../constants/url';
import Axios from 'axios';

const fetchEventsInProgress = () => {
    return {
        type: EVENTS_FETCH_IN_PROGRESS,
    };
};

const fetchEventsSuccess = (items) => {
    return {
        type: EVENTS_FETCH_SUCCESS,
        items: items,
    };
};

const fetchEventsError = (message) => {
    return {
        type: EVENTS_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchEvents = () => (dispatch) => {
    dispatch(fetchEventsInProgress());

    const url = EVENTS_FETCH_URL();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(fetchEventsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchEventsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const showAddEventDialog = (value) => {
    return {
        type: ADD_EVENT_DIALOG_SHOW,
        value,
    };
};

export const hideAddEventDialog = () => {
    return {
        type: ADD_EVENT_DIALOG_HIDE,
    };
};

export const setEventImageUrl = (value) => {
    return {
        type: EVENT_IMAGE_URL_SET,
        value,
    };
};

export const setEventName = (value) => {
    return {
        type: EVENT_NAME_SET,
        value,
    };
};

export const setEventVenue = (value) => {
    return {
        type: EVENT_VENUE_SET,
        value,
    };
};

export const setEventDescription = (value) => {
    return {
        type: EVENT_DESCRIPTION_SET,
        value,
    };
};

export const setEventStartTime = (value) => {
    return {
        type: EVENT_START_TIME_SET,
        value,
    };
};

export const setEventEndTime = (value) => {
    return {
        type: EVENT_END_TIME_SET,
        value,
    };
};

const addEventInProgress = () => {
    return {
        type: ADD_EVENT_IN_PROGRESS,
    };
};

const addEventSuccess = (value) => {
    return {
        type: ADD_EVENT_SUCCESS,
        value,
        message: 'Event added successfully',
    };
};

const addEventError = (message) => {
    return {
        type: ADD_EVENT_ERROR,
        message,
        variant: 'error',
    };
};

export const addEvent = (data, cb) => (dispatch) => {
    dispatch(addEventInProgress());

    const url = EVENTS_FETCH_URL();
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(addEventSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(addEventError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const updateEventInProgress = () => {
    return {
        type: UPDATE_EVENT_IN_PROGRESS,
    };
};

const updateEventSuccess = (value) => {
    return {
        type: UPDATE_EVENT_SUCCESS,
        value,
        message: 'Event updated successfully',
    };
};

const updateEventError = (message) => {
    return {
        type: UPDATE_EVENT_ERROR,
        message,
        variant: 'error',
    };
};

export const updateEvent = (id, data, cb) => (dispatch) => {
    dispatch(updateEventInProgress());

    const url = EVENT_URL(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(updateEventSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(updateEventError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteEventInProgress = () => {
    return {
        type: DELETE_EVENT_IN_PROGRESS,
    };
};

const deleteEventSuccess = (value) => {
    return {
        type: DELETE_EVENT_SUCCESS,
        value,
        message: 'Event deleted successfully',
    };
};

const deleteEventError = (message) => {
    return {
        type: DELETE_EVENT_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteEvent = (id, cb) => (dispatch) => {
    dispatch(deleteEventInProgress());

    const url = EVENT_URL(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(deleteEventSuccess(res.data && res.data.success));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(deleteEventError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const showDeleteEventDialog = (value) => {
    return {
        type: DELETE_EVENT_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteEventDialog = () => {
    return {
        type: DELETE_EVENT_DIALOG_HIDE,
    };
};
