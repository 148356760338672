import {
    DELETE_SUPPORT_FORM_URL, FETCH_TICKET_TYPE_URL,
    ORDERS_URL, SEND_TICKETS_URL,
    STATS_URL,
    SUPPORT_FORMS_URL,
    TICKETS_INFO_URL,
    UPDATE_SUPPORT_FORMS_URL,

    SESSION_INFO_URL, SLOT_ORDERS_URL,
} from '../constants/url';
import Axios from 'axios';
import {
    DELETE_FORM_DIALOG_HIDE,
    DELETE_FORM_DIALOG_SHOW, FETCH_TICKET_TYPE_ERROR, FETCH_TICKET_TYPE_IN_PROGRESS, FETCH_TICKET_TYPE_SUCCESS,
    NO_OF_TICKETS_SET,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS, SEND_TICKET_EMAIL_SET, SEND_TICKET_TYPE_SET,
    SEND_TICKETS_DIALOG_HIDE,
    SEND_TICKETS_DIALOG_SHOW, SEND_TICKETS_ERROR,
    SEND_TICKETS_IN_PROGRESS,
    SEND_TICKETS_SUCCESS,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS,
    SUPPORT_FORM_DELETE_ERROR,
    SUPPORT_FORM_DELETE_IN_PROGRESS,
    SUPPORT_FORM_DELETE_SUCCESS,
    SUPPORT_FORMS_FETCH_ERROR,
    SUPPORT_FORMS_FETCH_IN_PROGRESS,
    SUPPORT_FORMS_FETCH_SUCCESS,
    SUPPORT_FORMS_UPDATE_ERROR,
    SUPPORT_FORMS_UPDATE_IN_PROGRESS,
    SUPPORT_FORMS_UPDATE_SUCCESS,
    TICKETS_DATA_SET,
    TICKETS_INFO_FETCH_ERROR,
    TICKETS_INFO_FETCH_IN_PROGRESS,
    TICKETS_INFO_FETCH_SUCCESS,

    SESSION_INFO_FETCH_IN_PROGRESS,
    SESSION_INFO_FETCH_SUCCESS,
    SESSION_INFO_FETCH_ERROR, SLOT_ORDERS_FETCH_ERROR, SLOT_ORDERS_FETCH_IN_PROGRESS, SLOT_ORDERS_FETCH_SUCCESS,
} from '../constants/home';

export const showDeleteFormDialog = (value) => {
    return {
        type: DELETE_FORM_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteFormDialog = () => {
    return {
        type: DELETE_FORM_DIALOG_HIDE,
    };
};

export const showSendTicketsDialog = (value) => {
    return {
        type: SEND_TICKETS_DIALOG_SHOW,
        value,
    };
};

export const hideSendTicketsDialog = () => {
    return {
        type: SEND_TICKETS_DIALOG_HIDE,
    };
};

export const setNoOfTickets = (value) => {
    return {
        type: NO_OF_TICKETS_SET,
        value,
    };
};

export const setTicketsData = (value, valid) => {
    return {
        type: TICKETS_DATA_SET,
        value,
        valid,
    };
};

export const setSendTicketEmail = (value, valid) => {
    return {
        type: SEND_TICKET_EMAIL_SET,
        value,
        valid,
    };
};

export const setSendTicketType = (value) => {
    return {
        type: SEND_TICKET_TYPE_SET,
        value,
    };
};

const sendTicketsInProgress = () => {
    return {
        type: SEND_TICKETS_IN_PROGRESS,
    };
};

const sendTicketsSuccess = (value, message) => {
    return {
        type: SEND_TICKETS_SUCCESS,
        value,
        message,
    };
};

const sendTicketsError = (message) => {
    return {
        type: SEND_TICKETS_ERROR,
        message,
        variant: 'error',
    };
};

export const sendTickets = (data, cb) => (dispatch) => {
    dispatch(sendTicketsInProgress());

    Axios.post(SEND_TICKETS_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(sendTicketsSuccess(res.data && res.data.result, res.data && res.data.message));
            cb(res.data && res.data.message);
        })
        .catch((error) => {
            dispatch(sendTicketsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchTicketTypeInProgress = () => {
    return {
        type: FETCH_TICKET_TYPE_IN_PROGRESS,
    };
};

const fetchTicketTypeSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: FETCH_TICKET_TYPE_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
        variant: 'success',
    };
};

const fetchTicketTypeError = (message) => {
    return {
        type: FETCH_TICKET_TYPE_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchTicketType = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchTicketTypeInProgress());

    const url = FETCH_TICKET_TYPE_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchTicketTypeSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchTicketsInfoInProgress = () => {
    return {
        type: TICKETS_INFO_FETCH_IN_PROGRESS,
    };
};

const fetchTicketsInfoSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: TICKETS_INFO_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchTicketsInfoError = (message) => {
    return {
        type: TICKETS_INFO_FETCH_ERROR,
        message,
    };
};

export const fetchTicketsInfo = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchTicketsInfoInProgress());

    const url = TICKETS_INFO_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchTicketsInfoSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchTicketsInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchOrdersInProgress = () => {
    return {
        type: ORDERS_FETCH_IN_PROGRESS,
    };
};

const fetchOrdersSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: ORDERS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchOrdersError = (message) => {
    return {
        type: ORDERS_FETCH_ERROR,
        message,
    };
};

export const fetchOrders = (skip, limit, sortBy, order, searchKey, status) => (dispatch) => {
    dispatch(fetchOrdersInProgress());

    const url = ORDERS_URL(skip, limit, sortBy, order, searchKey, status);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchOrdersSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchOrdersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchStatsInProgress = () => {
    return {
        type: STATS_FETCH_IN_PROGRESS,
    };
};

const fetchStatsSuccess = (value) => {
    return {
        type: STATS_FETCH_SUCCESS,
        value,
    };
};

const fetchStatsError = (message) => {
    return {
        type: STATS_FETCH_ERROR,
        message,
    };
};

export const fetchStats = () => (dispatch) => {
    dispatch(fetchStatsInProgress());

    Axios.get(STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(fetchStatsSuccess(res && res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSupportFormsInProgress = () => {
    return {
        type: SUPPORT_FORMS_FETCH_IN_PROGRESS,
    };
};

const fetchSupportFormsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: SUPPORT_FORMS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchSupportFormsError = (message) => {
    return {
        type: SUPPORT_FORMS_FETCH_ERROR,
        message,
    };
};

export const fetchSupportForms = (skip, limit, sortBy, order) => (dispatch) => {
    dispatch(fetchSupportFormsInProgress());

    const url = SUPPORT_FORMS_URL(skip, limit, sortBy, order);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchSupportFormsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchSupportFormsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateSupportFormsInProgress = () => {
    return {
        type: SUPPORT_FORMS_UPDATE_IN_PROGRESS,
    };
};

const updateSupportFormsSuccess = (value, message) => {
    return {
        type: SUPPORT_FORMS_UPDATE_SUCCESS,
        value,
        message,
    };
};

const updateSupportFormsError = (message) => {
    return {
        type: SUPPORT_FORMS_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateSupportForms = (id, data, cb) => (dispatch) => {
    dispatch(updateSupportFormsInProgress());

    const url = UPDATE_SUPPORT_FORMS_URL(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(updateSupportFormsSuccess(res.data && res.data.result, 'Success'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(updateSupportFormsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteSupportFormInProgress = () => {
    return {
        type: SUPPORT_FORM_DELETE_IN_PROGRESS,
    };
};

const deleteSupportFormSuccess = (value, message) => {
    return {
        type: SUPPORT_FORM_DELETE_SUCCESS,
        value,
        message,
    };
};

const deleteSupportFormError = (message) => {
    return {
        type: SUPPORT_FORM_DELETE_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteSupportForm = (id, data, cb) => (dispatch) => {
    dispatch(deleteSupportFormInProgress());

    const url = DELETE_SUPPORT_FORM_URL(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            dispatch(deleteSupportFormSuccess(id, 'Deleted Successfully'));
            cb(res.data && res.data.success);
        })
        .catch((error) => {
            dispatch(deleteSupportFormError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchSessionInfoInProgress = () => {
    return {
        type: SESSION_INFO_FETCH_IN_PROGRESS,
    };
};

const fetchSessionInfoSuccess = (items) => {
    return {
        type: SESSION_INFO_FETCH_SUCCESS,
        items: items,
    };
};

const fetchSessionsInfoError = (message) => {
    return {
        type: SESSION_INFO_FETCH_ERROR,
        message,
    };
};

export const fetchSessionInfo = () => (dispatch) => {
    dispatch(fetchSessionInfoInProgress());

    const url = SESSION_INFO_URL();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            const items = res.data.result;
            // const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchSessionInfoSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchSessionsInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSlotOrdersInProgress = () => {
    return {
        type: SLOT_ORDERS_FETCH_IN_PROGRESS,
    };
};

const fetchSlotOrdersSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: SLOT_ORDERS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchSlotOrdersError = (message) => {
    return {
        type: SLOT_ORDERS_FETCH_ERROR,
        message,
    };
};

export const fetchSlotOrders = (skip, limit, sortBy, order, searchKey, status) => (dispatch) => {
    dispatch(fetchSlotOrdersInProgress());

    const url = SLOT_ORDERS_URL(skip, limit, sortBy, order, searchKey, status);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_CCCDashboard24'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchSlotOrdersSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchSlotOrdersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
