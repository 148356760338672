import React from 'react';
import './index.css';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const NavBar = (props) => {
    const onClick = () => {
        localStorage.removeItem('authorizationToken_CCCDashboard24');
        localStorage.removeItem('refreshToken_CCCDashboard24');
        props.history.push('/login');
    };

    return (
        <div className="navbar">
            <Button
                className="disconnect_button"
                onClick={() => onClick()}>
                Logout
            </Button>
        </div>
    );
};

NavBar.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(NavBar);
