import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { setNoOfTickets, setTicketsData } from '../../../actions/home';

const TicketsTextField = (props) => {
    const handleChange = (value, obj, type) => {
        const object = { ...props.value };
        if (object[obj]) {
            object[obj][type] = value;
            if (type === 'email') {
                object[obj]['email_valid'] = true;
            } else if (type === 'name') {
                object[obj]['name_valid'] = true;
            }
        } else {
            object[obj] = {};
            object[obj][type] = value;
            object[obj]['email_valid'] = true;
            object[obj]['name_valid'] = true;
        }

        props.onChange(object, true);
    };

    const handleRemove = (value, name) => {
        const tickets = props.noOfTickets - 1;
        props.setNoOfTickets(tickets);

        const object = { ...props.value };
        if (object[name]) {
            delete object[name];
        }

        if (object && Object.keys(object).length) {
            Object.keys(object).map((key) => {
                const splitValue = key && key.split('ticket');
                if (splitValue && splitValue.length && splitValue[1]) {
                    if (Number(splitValue[1]) > value) {
                        const temp = object[key];
                        delete object[key];

                        object[`ticket${splitValue[1] - 1}`] = { ...temp };
                    }
                }
            });
        }

        props.onChange(object, true);
    };

    return (
        <div className="tickets_data">
            {props.noOfTickets && props.noOfTickets > 0 &&
                [...Array(Number(props.noOfTickets))].map((item, value) => {
                    const name = `ticket${value + 1}`;
                    const nameValid = props.value && props.value[name]
                        ? !props.value[name]['name_valid'] : false;
                    const emailValid = props.value && props.value[name]
                        ? !props.value[name]['email_valid'] : false;
                    const ticketName = props.value && props.value[name]
                        ? props.value[name].name : '';
                    const email = props.value && props.value[name]
                        ? props.value[name].email : '';
                    const company = props.value && props.value[name]
                        ? props.value[name].company : '';

                    return (
                        <div key={value} className="ticket_row">
                            <span>
                                {`Ticket #${value + 1}`}
                                {(props.noOfTickets === 1 || value === 0)
                                    ? null
                                    : <Button onClick={() => handleRemove(value + 1, name)}>
                                        <RemoveIcon/>
                                    </Button>}
                            </span>
                            <div>
                                <TextField
                                    className={nameValid ? 'error_tickets_email' : ''}
                                    error={nameValid}
                                    // errorText={nameValid ? variables[props.lang]['name_error'] : ''}
                                    id={`full_name${value + 1}`}
                                    name={`fullName${value + 1}`}
                                    placeholder={variables[props.lang]['full_name']}
                                    value={ticketName}
                                    onChange={(e) => handleChange(e, `ticket${value + 1}`, 'name')}/>
                                <TextField
                                    className={emailValid ? 'error_tickets_email email_text_field' : 'email_text_field'}
                                    error={emailValid}
                                    // errorText={emailValid ? variables[props.lang]['email_address_error'] : ''}
                                    id={`email_address${value + 1}`}
                                    name={`emailAddress${value + 1}`}
                                    placeholder={variables[props.lang]['email_address']}
                                    value={email}
                                    onChange={(e) => handleChange(e, `ticket${value + 1}`, 'email')}/>
                                <TextField
                                    className={nameValid ? 'error_tickets_email' : ''}
                                    error={nameValid}
                                    // errorText={nameValid ? variables[props.lang]['name_error'] : ''}
                                    id={`company_project${value + 1}`}
                                    name={`company_project${value + 1}`}
                                    placeholder={variables[props.lang]['company_project']}
                                    value={company}
                                    onChange={(e) => handleChange(e, `ticket${value + 1}`, 'company')}/>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

TicketsTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    noOfTickets: PropTypes.array.isRequired,
    setNoOfTickets: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.noOfTickets.data,
        noOfTickets: state.home.noOfTickets.count,
    };
};

const actionsToProps = {
    onChange: setTicketsData,
    setNoOfTickets,
};

export default connect(stateToProps, actionsToProps)(TicketsTextField);
