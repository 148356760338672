const URL = 'https://api-c24.layerzerox.com';

export const SEND_TICKETS_URL = `${URL}/admin/orders`;
export const FETCH_TICKET_TYPE_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/ticket-types?${params.join('&')}`;
};
export const TICKETS_INFO_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    // if (searchKey) {
    //     params.push(`searchKey=${searchKey}`);
    // }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/tickets?${params.join('&')}`;
};

export const ORDERS_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/orders?${params.join('&')}`;
};
export const SUPPORT_FORMS_URL = (skip, limit, sortBy, order) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/support-forms?${params.join('&')}`;
};
export const UPDATE_SUPPORT_FORMS_URL = (id) => {
    return `${URL}/admin/support-forms/${id}/resolve`;
};
export const DELETE_SUPPORT_FORM_URL = (id) => {
    return `${URL}/admin/support-forms/${id}`;
};

export const STATS_URL = `${URL}/stats`;
export const USER_LOGIN_URL = `${URL}/admin/login`;
export const USER_ACCESS_TOKEN = `${URL}/admin/auth/refresh-token`;
export const EVENT_URL = (id) => `${URL}/admin/events/${id}`;
export const GATED_ACCESS_URL = (eventId, id) => `${URL}/admin/events/${eventId}/gated-accesses/${id}`;
export const GATED_ACCESSES_FETCH_URL = (eventId) => `${URL}/admin/events/${eventId}/gated-accesses`;
export const ENTRY_URL = (eventId, gatedAccessId, id) => `${URL}/admin/events/${eventId}/gated-accesses/${gatedAccessId}/entries/${id}`;
export const ADD_ENTRY_URL = (eventId, ID) => `${URL}/admin/events/${eventId}/gated-accesses/${ID}/entries`;

export const EVENTS_FETCH_URL = () => {
    return `${URL}/admin/events`;
};

export const ENTRIES_FETCH_URL = (eventId, ID, skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/admin/events/${eventId}/gated-accesses/${ID}/entries?${params.join('&')}`;
};

export const SLOT_ORDERS_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/slot-orders?${params.join('&')}`;
};
// export const SESSION_INFO_URL = (day) => {
//     const params=[];
//     if(day && day === 'DAY_1' || 'DAY_2' || 'DAY_3') {
//         return `${URL}/sessions/?day=${day}`;
//     } else {
//         return `${URL}/sessions`
//     }
// }

export const SESSION_INFO_URL = () => `${URL}/sessions`;
export const urlUpdateSession = (id) => `${URL}/sessions/${id}`;
