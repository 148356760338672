import * as React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
import CircularProgress from '../../../components/CircularProgress';
import {
    fetchTicketsInfo,
    hideSendTicketsDialog,
    sendTickets,
    setNoOfTickets,
    setTicketsData,
} from '../../../actions/home';
import TicketsData from './TicketsData';
import variables from '../../../utils/variables';
import AddIcon from '@mui/icons-material/Add';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { isValidEmail } from '../../../utils/validation';
import { showMessage } from '../../../actions/snackbar';
import TypeSelectField from './TypeSelectField';
import EmailAddressTextField from './EmailAddressTextField';

const SendTicketsDialog = (props) => {
    const handleChange = () => {
        let valid = true;
        let object = { ...props.ticketsInfo };
        if (Object.keys(object).length > Number(props.noOfTickets)) {
            object = {};
            Object.keys(props.ticketsInfo).map((key, index) => {
                if (Number(props.noOfTickets) < index + 1) {
                    return null;
                }

                object = {
                    ...object,
                    [key]: props.ticketsInfo[key],
                };

                return null;
            });
        }

        Object.keys(object).map((key, index) => {
            const emailValid = key && object[key] && object[key]['email_valid'] &&
                object[key].email && isValidEmail(object[key].email);
            object[key]['email_valid'] = emailValid;
            const nameValid = key && object[key] && object[key]['name_valid'] &&
                object[key].name !== '';
            object[key]['name_valid'] = nameValid;
            if (!emailValid || !nameValid) {
                valid = false;
            }

            return null;
        });

        if (Object.keys(object).length !== Number(props.noOfTickets)) {
            [...Array(props.noOfTickets).keys()].map((value) => {
                if (Object.keys(object).length > value) {
                    return null;
                }

                object[`ticket${value + 1}`] = {
                    email: '',
                    name: '',
                    company: '',
                    email_valid: false,
                    name_valid: false,
                    company_valid: false,
                };

                return null;
            });

            valid = false;
        }

        props.setTicketsData(object);

        // create order data
        if (props.noOfTickets > 0 && valid) {
            const array = [];
            Object.keys(object).map((key) => {
                const obj = {
                    name: object[key] && object[key].name,
                    company: object[key] && object[key].company,
                    email_address: object[key] && object[key].email,
                };

                return array.push(obj);
            });

            const data = {
                type: props.type,
                numberOfTickets: props.noOfTickets,
                emailTicketsTo: props.email,
                ticketsInfo: array,
            };

            if (data) {
                props.sendTickets(data, (result) => {
                    if (result) {
                        props.showMessage(result);
                        props.hideDialog();
                        props.fetchTicketsInfo(DEFAULT_SKIP, DEFAULT_LIMIT);
                    }
                });
            // } else {
            //     props.onChange('checkout');
            //     props.createOrderSuccess({
            //         user: 'not logged in',
            //     });
            }
        } else {
            props.showMessage('You have entered an invalid Name or Email address');
        }
    };

    const handleAdd = () => {
        props.setNoOfTickets(props.noOfTickets + 1);
    };

    const disable = props.type === '' || props.email === '';
    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog send_dialog add_event_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                Send Tickets
            </DialogTitle>
            <div className="tickets_info">
                <div className="type_email">
                    <TypeSelectField />
                    <EmailAddressTextField />
                </div>
                <TicketsData />
                <Button onClick={handleAdd}>
                    <AddIcon/>
                    <p/>
                    {variables[props.lang].add_tickets}
                </Button>
            </div>
            {props.inProgress ? <CircularProgress className="full_screen"/> : null}
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                {props.inProgress
                    ? <Button
                        autoFocus
                        className="add_button"
                        disabled={disable}>
                        <CircularProgress />
                        Confirm
                    </Button>
                    : <Button
                        autoFocus
                        className="add_button"
                        disabled={disable}
                        onClick={handleChange}>
                    Confirm
                    </Button>}
            </DialogActions>
        </Dialog>
    );
};

SendTicketsDialog.propTypes = {
    email: PropTypes.string.isRequired,
    fetchTicketsInfo: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    noOfTickets: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    sendTickets: PropTypes.func.isRequired,
    setNoOfTickets: PropTypes.func.isRequired,
    setTicketsData: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    ticketsInfo: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.sendTicketsDialog.open,
        value: state.home.sendTicketsDialog.value,
        noOfTickets: state.home.noOfTickets.count,
        ticketsInfo: state.home.noOfTickets.data,
        email: state.home.emailSendTicket.value,
        type: state.home.typeSendTicket.value,
        inProgress: state.home.sendTickets.inProgress,
    };
};

const actionToProps = {
    hideDialog: hideSendTicketsDialog,
    setNoOfTickets,
    setTicketsData,
    showMessage,

    fetchTicketsInfo,
    sendTickets,
};

export default withRouter(connect(stateToProps, actionToProps)(SendTicketsDialog));
