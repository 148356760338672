import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import {
    addSession,
    hideAddSessionDialog,
    setSessionDescription,
    setSessionDate,
    setSessionImageUrl,
    setSessionDay,
    setSessionName,
    updateSession,
} from '../../../actions/sessions';
import { fetchSessionInfo } from '../../../actions/home';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../AddEventDialog/index.css';
import TextField from '../../../components/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Stack from '@mui/material/Stack';
import { InputAdornment, MenuItem, Select, TextField as MaterialTextField } from '@mui/material';
import calendarIcon from '../../../assets/calendar.png';
import CircularProgress from '../../../components/CircularProgress';
import { showMessage } from '../../../actions/snackbar';
import './index.css';

const AddSessionDialog = (props) => {
    const handleAdd = () => {
        const data = {
            name: props.name,
            day: props.day,
        };

        if (props.description) {
            data.description = props.description;
        }
        // if (props.day) {
        //     data.day = props.day;
        // }
        if (props.image) {
            data.imageURL = props.image;
        }
        if (props.date) {
            data.date = props.date;
        }

        if (props.name && props.day) {
            props.addSession(data, (result) => {
                if (result) {
                    props.fetchSessionInfo();
                    props.hideDialog();
                }
            });
        } else {
            props.showMessage('Please Add Name and Day fields');
        }
    };

    const handleUpdate = () => {
        const data = {};

        if (props.value && (props.value.name !== props.name) && props.name !== '') {
            data.name = props.name;
        }
        if (props.value && (props.value.description !== props.description) && props.description !== '') {
            data.description = props.description;
        }
        if (props.value && (props.value.day !== props.day) && props.day !== '') {
            data.day = props.day;
        }
        if (props.value && (props.value.imageURL !== props.image) && props.image !== '') {
            data.imageURL = props.image;
        }
        if (props.value && (props.value.date !== props.date) && props.date !== '') {
            data.day = props.date;
        }

        if (Object.keys(data).length === 0) {
            props.showMessage('Every thing is upto date');

            return;
        }

        if (props.name !== '') {
            props.updateSession(props.value && props.value._id, data, (result) => {
                if (result) {
                    props.fetchSessionInfo();
                    props.hideDialog();
                }
            });
        } else {
            props.showMessage('Please Add Session Name');
        }
    };

    const handleChange = (event) => {
        props.setSessionDay(event.target.value);
    };

    const daysOptions = [{
        name: 'DAY_1',
        value: 'DAY_1',
    }, {
        name: 'DAY_2',
        value: 'DAY_2',
    }, {
        name: 'DAY_3',
        value: 'DAY_3',
    }];

    const disable = props.name === '' || props.inProgress;

    return (
        <Dialog
            aria-describedby="add-event-dialog-description"
            aria-labelledby="add-event-dialog-title"
            className="dialog add_event_dialog session_dialog"
            open={props.open}
            onClose={props.hideDialog}>
            <DialogTitle id="alert-dialog-title">
                {props.value && props.value._id
                    ? 'Update Session'
                    : 'Add Session'}
            </DialogTitle>
            <DialogContent>
                {props.inProgress ? <CircularProgress className="full_screen"/> : null}
                <div className="row">
                    <h2>Image URL</h2>
                    <TextField
                        id="image_url"
                        name="imageUrl"
                        placeholder="Image URL"
                        value={props.image}
                        variant="filled"
                        onChange={props.setSessionImageUrl}/>
                </div>
                <div className="row">
                    <h2>Session Name *</h2>
                    <TextField
                        id="event_name"
                        name="eventName"
                        placeholder="Session Name"
                        value={props.name}
                        variant="filled"
                        onChange={props.setSessionName}/>
                </div>
                <div className={props.value && props.value._id
                    ? 'update_session row session_day'
                    : 'row session_day'}>
                    <h2>Session Day*</h2>
                    <Select
                        className="text_field select_field"
                        disabled={!!(props.value && props.value._id)}
                        id="demo-simple-select-helper"
                        value={props.day}
                        onChange={handleChange}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {daysOptions && daysOptions.length > 0 &&
                            daysOptions.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={item.value} onChange={() => handleChange(item.value)}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </div>
                <div className="row">
                    <h2>Session Description</h2>
                    <TextField
                        id="event_description"
                        name="eventDescription"
                        placeholder="Event Description"
                        value={props.description}
                        variant="filled"
                        onChange={props.setSessionDescription}/>
                </div>
                <div className="row">
                    <h2>Date</h2>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack className="date_field" spacing={3}>
                            <MobileDateTimePicker
                                for
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="calendar" src={calendarIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                                minDateTime={new Date()}
                                renderInput={(params) => <MaterialTextField
                                    className="text_field"
                                    placeholder="Session Date"
                                    {...params} />}
                                value={props.date}
                                onChange={props.setSessionDate}/>
                        </Stack>
                    </LocalizationProvider>
                </div>
                {/* <div className="row"> */}
                {/*    <h2>End Date</h2> */}
                {/*    <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                {/*        <Stack className="date_field" spacing={3}> */}
                {/*            <MobileDateTimePicker */}
                {/*                for */}
                {/*                InputProps={{ */}
                {/*                    startAdornment: ( */}
                {/*                        <InputAdornment position="start"> */}
                {/*                            <img alt="calendar" src={calendarIcon}/> */}
                {/*                        </InputAdornment> */}
                {/*                    ), */}
                {/*                }} */}
                {/*                minDateTime={new Date()} */}
                {/*                renderInput={(params) => <MaterialTextField */}
                {/*                    className="text_field" */}
                {/*                    placeholder="End Time" */}
                {/*                    {...params} />} */}
                {/*                value={props.endTime} */}
                {/*                onChange={handleEndTime}/> */}
                {/*        </Stack> */}
                {/*    </LocalizationProvider> */}
                {/* </div> */}
            </DialogContent>
            <DialogActions className="actions">
                <Button onClick={props.hideDialog}>Cancel</Button>
                {props.value && props.value._id
                    ? <Button autoFocus className="add_button" disabled={disable} onClick={handleUpdate}>
                        Update
                    </Button>
                    : <Button autoFocus className="add_button" disabled={disable} onClick={handleAdd}>
                        Add
                    </Button>}
            </DialogActions>
        </Dialog>
    );
};

AddSessionDialog.propTypes = {
    addSession: PropTypes.func.isRequired,
    day: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    fetchSessionInfo: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setSessionDate: PropTypes.func.isRequired,
    setSessionDay: PropTypes.func.isRequired,
    setSessionDescription: PropTypes.func.isRequired,
    setSessionImageUrl: PropTypes.func.isRequired,
    setSessionName: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    date: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.sessions.addSession.dialog,
        value: state.sessions.addSession.value,
        inProgress: state.sessions.addSession.inProgress,
        image: state.sessions.addSession.image,
        name: state.sessions.addSession.name,
        day: state.sessions.addSession.day,
        date: state.sessions.addSession.date,
        description: state.sessions.addSession.description,
    };
};

const actionToProps = {
    addSession,
    updateSession,
    fetchSessionInfo,
    hideDialog: hideAddSessionDialog,

    setSessionImageUrl,
    setSessionName,
    setSessionDay,
    setSessionDescription,
    setSessionDate,
    showMessage,
};

export default withRouter(connect(stateToProps, actionToProps)(AddSessionDialog));
